import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Projects() {
  return (
    <Layout subTitle="projects">
      <SEO title="Projects" />
      <div>
        <h3>mr speaker</h3>
        <p>
          <a href="https://mrspeaker.jack-roberts.com" target="_blank" rel="noopener noreferrer">
            Mr Speaker
          </a>{" "}
          allows the user to convert a text file to an MP3 for later listening.
          Feel free to 
          {" "}<a href="https://mrspeaker.jack-roberts.com/signup" target="_blank" rel="noopener noreferrer">
            signup for an account 
          </a> {" "}
           and take it for a spin.
          Built it to get my hands dirty with
          Serverless and cloudformation as well as the full dev lifecycle for
          these apps. Also wanted to do more with Node and React.
        </p>
        <p>
          This is an MVP. Will add other document types (PDF, Word, etc) and
          URLs as targets in the future. 
          Also need to clean up a few UI inconveniences. Will
          write a blog post soon detailing the app structure, how the CI/CD
          dev cycle works and how it gets pushed to prod.
        </p>
        <p>
          Source code for both the{" "}
          <a href="https://github.com/jroberts64/mrspeaker-api" 
            target="_blank" 
            rel="noopener noreferrer">
            api
          </a>{" "}
          and{" "}
          <a
            href="https://github.com/jroberts64/mrspeaker-client"
            target="_blank"
            rel="noopener noreferrer"
          >
            client
          </a>{" "}
          can be found on github.
        </p>
        <h3>raspberry pi btc dashboard</h3>
          <p>
            This was a {" "}
            <a
            href="https://github.com/jroberts64/btc-dashboard-pi"
            target="_blank"
            rel="noopener noreferrer"
            >fun little project
            </a>
            . It combined my first Raspberry Pi
            project with a bitcoin dashboard of metrics I like to keep abreast of.
            Includes a 7" touchscreen display and 
            motion sensor to save power and the screen.
          </p>
          <p>
            You can see the dashboard <a
            href="http://btc.jack-roberts.com/"
            target="_blank"
            rel="noopener noreferrer"
            >here.</a> It's sized to perfectly fit the
            Raspberry Pi 7" display, but looks ok on a desktop browser.
            Improvements I'd like to make include:<p/>
            <ul>
              <li>Dark mode</li>
              <li>Ability to customize metrics</li>
              <li>Different dashboard styles</li>
              <li>Better docs</li>
              <li>Seperate the dashboard UI from the data being displayed.
                It's too coupled right now.
              </li>
            </ul>
          </p>
        <h3>bitcoin price checker</h3>
        <p>
          An Arduino based project written in C++. It's a simple Bitcoin price
          checker that:
        </p>
        <ul>
          <li>Wakes up every 15 minutes</li>
          <li>Connects to WiFi</li>
          <li>Pings a service for the BTC/USD price</li>
          <li>Writes the price to an ePaper display</li>
          <li>Goes back to sleep</li>
        </ul>
        <p>
          When, upon awakening, it can't find a wifi network it can connect to,
          it will go into access point mode. This mode creates a wifi network
          and simple web site to configure the wifi settings. See the{" "}
          <a
            href="https://github.com/jroberts64/bitcoin-price-check"
            target="_blank"
            rel="noopener noreferrer"
          >
            readme on github
          </a>{" "}
          for more information.
        </p>
      </div>
    </Layout>
  )
}
